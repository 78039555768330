.custom_wrapper {
  width: 45%;
  font-size: 1.25rem;
}

.custom_label {
  color: #7A86A1;
  font-size: 14px;
}

.custom_select {
  background-color: #F6F9FD;
  border: 1px solid #DAE3EB;
  border-radius: 13px;
  min-height: 50px;
}

.custom_select > div {
  background-color: #F6F9FD;
  border: 1px solid #DAE3EB;
  border: none;
  border-radius: 13px;
  min-height: 50px;
};

.custom_select:disabled {
  background-color: #e9ecef !important;
}
