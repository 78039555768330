.dashed-list {
  list-style-type: none;
}

.dashed-list li:before {
  content: "-";
  position: absolute;
  margin-left: -20px;
}

.disc-list {
  list-style-type: disc;
}

.list-none {
  list-style: none;
}

.info-page-image {
  max-width: 95%;
  margin: 20px 0;
}

.note {
  padding: 16px;
  background-color: #00BCF2;
  border-radius: 10px;
  border: 1px solid #0078D4;
  margin-top: 20px;
  color: #000000; 
  font-weight: bold;
}

.note p {
  margin: 0;
  padding: 0;
}
