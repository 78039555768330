.header {
  color: #253858;
}

.text {
  color: #7A86A1;
  font-size: 14px;
}

.emailInputContainer {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  width: 50%;
}

.emailChipsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.emailChip {
  border: 1px solid #ccc;
  font-size: small;
  border-radius: 16px;
  padding: 5px 10px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
}

.emailDelete {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}

.emailInputField {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 5px;
  margin: 5px 0;
}

.emailError {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}