.responsive {
  display: flex;
  /* justify-content: flex-start;
  align-items: flex-start; */
}

.noheader {
  display: block;
}

.nav_width {
  width: 20%;
}

.main_width {
  flex:1;
  width: 80%;
}
