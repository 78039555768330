.dashed-list {
  list-style-type: none;
}

.dashed-list li:before {
  content: "-";
  position: absolute;
  margin-left: -20px;
}

.disc-list {
  list-style-type: disc;
}

.table {
  border-collapse: collapse;
  width: 50%,
}

.cell {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.no-bullets {
  list-style-type: none
}

.cookies-container {
}

.cookies-table-container {
  overflow-x: auto;
}

.cookies-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.cookies-table th,
.cookies-table td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

.cookies-table th {
  background-color: #f2f2f2;
}

.no-bullets {
  list-style-type: none;
  padding: 0;
}
