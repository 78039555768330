.lb-fpass,
.sign-up,
.privacy-notice-btn {
  color: #c12331;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  text-align: right;
  font-size: 15px;
  font-style: normal;
  margin-bottom: 0;
}

.lb-fpass:hover,
.sign-up:hover,
.privacy-notice-btn {
  color: #c12331;
}

.error {
  font-weight: 600;
  color: rgb(201, 37, 44);
  font-size: 14px;
}

.accordion .accordion-header .accordion-button,
.accordion-header .accordion-button.collapsed {
  background-color: #fafafb;
  color: black;
  box-shadow: none;
}

.accordion h2.accordion-header .accordion-button::after {
  transform: rotate(90deg);
}

.accordion h2.accordion-header .accordion-button:not(.collapsed):after {
  transform: rotate(0deg);
}

.accordion .accordion-item:not(:first-of-type) {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-collapse,
.accordion-body {
  background-color: #eeeef0;
}

.login-overlay {
  position: absolute;
  background: rgba(57, 55, 72, 0.22);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
}

.pagination .page-item .page-link {
  color: #c12331;
}

.pagination .page-item.active .page-link {
  background-color: #c12331;
  border-color: #c12331;
}

.pagination .page-item.disabled .page-link {
  color: #adb3b8;
}

.nav-tabs .nav-link {
  color: #cf6871;
}

.nav-tabs .nav-link:hover {
  color: #8d1621;
}

.nav-tabs .nav-link.active {
  background-color: #c12331 !important;
  color: white !important;
}

.form-check .form-check-input:checked {
  background-color: #c12331;
  border-color: #c12331;
}

.tooltip .tooltip-inner {
  max-width: 300px;
  padding: 0.125rem 0.125rem;
}

.dashboard>.table-responsive {
  height: 460px;
}

.table-spinner-container {
  height: 412px;
}

.advanced_search_error {
  color: #c12331;
}


.custom-border {
  border: "1px solid #ced4da75";
}

.padding-bottom-20 {
  border-top: "0px";
  padding-bottom: "20px";
}

.custom-font-size {
  font-size: "14px";
  color: "#7a86a1";
}

.spinner-color {
  color: #c12331;
}

.margin-left-20 {
  margin-left: 20px;
}

.font-14px {
  font-size: 14px;
}

.popover-header {
  background: "#FAFAFB";
  font-size: "14px";
  font-weight: "bold";
}

.custom-pointer {
  cursor: "pointer";
  color: "blue";
}

.custom-padding-bottom {
  padding-bottom: 20px;
}

.custom-hr {
  border-top: 3px solid black;
}

.custom-rounded {
  border-radius: 5px;
}

.custom-text {
  color: #7a86a1;
}

.custom-margin {
  margin: 20px;
}

.custom-text-color {
  color: grey;
}

.custom-flex-basis {
  flex-basis: 80%;
}

.custom-margin-top {
  margin-top: 10px;
}

.custom-margin-left {
  margin-left: 5px;
}

.custom-outline {
  outline: none;
}

.custom-bg {
  background-color: #FAFAFB;
}

.custom-max-width {
  max-width: 30%;
}

.custom-margin-top30 {
  margin-top: 30px;
}

.custom-button-style {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
}

.custom-blue {
  color: blue;
}

.custom-margin30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.custom-padding-top {
  padding-top: 20px;
}

.custom-padding10 {
  padding: 10px;
}


.custom-margin10 {
  margin-right: 10px,
}

.custom-width-300 {
  width: 300px;
}

.custom-gap {
  gap: 16px;
}

.custom-flex-basis-18 {
  flex-basis: 18%;
}

.custom-padding-5 {
  padding: 5px;
}

.disabled-style {
  cursor: not-allowed;
  color: #888;
}

.enabled-style {
  cursor: pointer;
  color: inherit;
}

.cookie-settings-modal {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  max-width: 600px;
  margin: auto;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.modal-title {
  font-family: "teleNeo-regular";
  color: #333;
}

.section-title {
  font-family: "teleNeo-regular";
  color: gray;
}

.cookie-section {
  margin-bottom: 20px;
}

.cookie-section-content {
  padding-top: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:checked+.slider:before {
  transform: translateX(14px);
}

.wrapper {
  min-height: 100vh;
}

.bg_image {
  background-image: url("./assets/images/MeetPeteLogo.jpg");
  background-size: cover;
  background-position: center center;
}

.code-review-style {
  width: 40% !important;
}