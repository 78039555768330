html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar-track {
	background-color: rgb(46, 54, 69);
}

::-webkit-scrollbar {
	width: 7px;
	background-color: rgb(46, 54, 69);
}

::-webkit-scrollbar-thumb {
	background-color: rgb(210, 210, 210);
	border: 0px;
	background-clip: padding-box;
	border-radius: 5px;
}