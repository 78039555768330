.custom_wrapper {
  width: 45%;
}

.select_font_size {
  font-size: 1.25rem;
}

.custom_label {
  color: #7a86a1;
  font-size: 14px;
}

.custom_select {
  background-color: #f6f9fd !important;
  border: 1px solid #dae3eb !important;
  border-radius: 13px !important;
  height: 50px !important;
}

.custom_select:disabled {
  background-color: #e9ecef !important;
}
