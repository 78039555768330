.custom_wrapper {
  width: 45%;
}

.custom_label {
  color: #7a86a1;
  font-size: 14px;
}

.custom_input {
  background-color: #f6f9fd !important;
  border: 1px solid #dae3eb !important;
  border-radius: 13px !important;
  height: 50px !important;
}

.custom_textarea {
  background-color: #f6f9fd !important;
  border: 1px solid #dae3eb !important;
  border-radius: 13px !important;
  min-height: 100px !important;
}

.icon {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}

.icon.sm {
  width: 20px;
  height: 20px;
}

.icon.lg {
  width: 40px;
  height: 40px;
}

::placeholder {
  font-size: 1.25rem;
}

:-ms-input-placeholder {
  font-size: 1.25rem;
}

::-ms-input-placeholder {
  font-size: 1.25rem;
}

.inputGroup {
  position: relative;
}

.inputWithToggle {
  padding-right: 30px;
}

.eyeIcon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.inputWithClear {
  padding-right: 30px;
}

.clearIcon {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
