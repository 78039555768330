.responsive {
  display: inline-flex;
}

.container {
  min-height: 100vh;
  /* UI Properties */
  background: transparent linear-gradient(173deg, #fcfcfc 0%, #f5f4f9 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
}

.container_open {
  width: 250px;
}

.container_close {
  width: 100px;
}

.iw_logo {
  position: relative;
  top: 43px;
  left: 20px;
  width: 170px;
  height: 50px;
  background: transparent url("../../assets/images/IWDGPeteLogo.png") 0% 0% no-repeat padding-box;
  background-size: auto 50px;
  opacity: 1;
}

.hamburger,
.hamburger_close {
  /* Layout Properties */
  position: relative;
  top: 60px;
  left: 40px;
  width: 25px;
  height: 25px;
  /* UI Properties */
  background: transparent url("../../assets/images/03.png") 0% 0% no-repeat padding-box;
  background-position: center;
  opacity: 1;
  cursor: pointer;
}

.hamburger_close {
  left: 34px;
}

.display_none {
  display: none;
}

/* menu section */
.menu {
  margin: 100px 0 50px 25px;
}

.menu_section {
  margin: 50px 0;
}

.menu_section_item {
  /* UI Properties */
  text-align: left;
  letter-spacing: 1.2px;
  color: #7a86a1;
  text-transform: uppercase;
  font-size: 12px;
}

.menu_ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.menu_ul li {
  margin: 10px 0;
}

.menu_ul li a:hover {
  color: #085a69;
}

.menu_icon_item {
  /* Layout Properties */
  width: 20px;
  height: 20px;
  /* UI Properties */
  background: transparent no-repeat padding-box;
  opacity: 1;
  margin-right: 10px;
}

.menu_icon_item_close {
  position: relative;
  left: 10px;
  margin: 20px 0;
}

.menu_item {
  /* Layout Properties */
  text-align: left;
  letter-spacing: 0px;
  color: #7a86a1;
  opacity: 1;
  text-transform: capitalize;
  text-decoration: none;
}

.menu_item_active {
  /* Layout Properties */
  text-align: left;
  letter-spacing: 0px;
  color: #c12331;
  text-decoration: underline;
  opacity: 1;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: bold;
}

.menu_item_active:hover,
.menu_item:hover {
  color: #8d1621 !important;
}

.footer_text {
  text-align: left;
  letter-spacing: 0px;
  color: #7a86a1;
  opacity: 1;
  font-size: 11px;
  text-align: center;
  margin: 20px 0;
}

.footer_text_bold {
  font-weight: bold;
}

.iw_logo_bottom {
  display: block;
  margin: auto;
  width: 80%;
  height: auto;
}

.padding-20-25 {
  padding: 20px 25px;
}