.cookie-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #EAEAEA;
}

.cookie-section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.necessary-cookies {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600; 
}

.cookie-settings-modal {
  background-color: #FFFFFF;
  color: #333333;
  font-family: 'Segoe UI', Arial, sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 600px;
  z-index: 1000;
  line-height: 1.6;
  border: 1px solid #CCCCCC;
}

.cookie-settings-modal h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #000000;
}

.close-btn {
  background: transparent;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.cookie-settings-modal p {
  font-size: 14px;
  margin-bottom: 20px;
  color: #666666;
}

.cookie-settings-modal .cookie-section {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #EAEAEA;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px;
  vertical-align: middle;
}

.slider {
  position: absolute;
  cursor: not-allowed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #CCCCCC;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

 input:checked + .slider {
  background-color: #c12331;
  margin: none;
}

input:focus + .slider {
  box-shadow: 0 0 1px #c12331;
  margin: none;
}

input:checked + .slider:before {
  transform: translateX(26px);
  margin: none;
}

.cookie-settings-modal button {
  font-size: 16px;
  color: white;
  background-color: #c12331;
  border: none;
  padding: 10px 20px;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 4px;
  display: block;
  width: 100%;
  transition: background-color 0.3s ease;
}
