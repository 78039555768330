.custom_button {
  padding: 10px 40px !important;
  border-radius: 20px !important;
}

.gradient_button {
  background: transparent linear-gradient(90deg, #c12339 0%, #b8264c 100%) 0% 0% no-repeat padding-box !important;
  border: none !important;
}

.gradient_button:hover {
  opacity: 0.7 !important;
}

.gray_button {
  background-color: #eff4fc !important;
  color: #253858 !important;
  border: none !important;
}

.gray_button:hover {
  background-color: #eff4fc !important;
  color: currentColor !important;
  opacity: 0.7 !important;
}

.primary {
  background-color: #c12331 !important;
  border: none !important;
  color: white !important;
}

.primary:hover {
  background-color:#8d1621 !important;
}

.icon_button {
  height: 50px;
  width: 50px;
}
