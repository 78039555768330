.header_container {
  margin: 50px;
  border-bottom: 1px solid rgba(81, 161, 222, 0.2);
  display: flex;
  justify-content: space-between;
}

.header_user {
  text-align: left;
  letter-spacing: 0px;
  color: #7a86a1;
  opacity: 0.5;
  font-size: 11px;
  margin: 20px 0;
}

.header_main {
  text-align: left;
  letter-spacing: 0px;
  color: #253858;
  opacity: 1;
  margin-bottom: 30px;
  font-size: 24px;
}

.select_ {
  border: none;
  margin-top: 15px;
}

.header__dropdowns {
  display: flex;
  gap: 40px;
}
