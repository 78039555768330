.btnMicrosoft {
  width: 100%;
  background-color: #0e0e0f !important;
  color: #5E5E5E;
  border: none;
  border-radius: 6px;
  display: inline-flex !important;
  align-items: flex-start;
  justify-content: flex-start;
}

.btnMicrosoft .msLogo {
  width: 34px;
  height: 30px;
  margin-right: 20%;
}

.btnMicrosoft:hover {
  background-color: #e5e5e5 !important;
}

.orDivider {
  display: flex;
  align-items: center;
  color: #cccccc;
}

.orDivider::before,
.orDivider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #cccccc;
  margin: 0 10px;
}