.dashed-list {
  list-style-type: none;
}

.dashed-list li:before {
  content: "-";
  position: absolute;
  margin-left: -20px;
}

.disc-list {
  list-style-type: disc;
}

.list-none {
  list-style: none;
}

.info-page-image {
  max-width: 95%;
  margin: 20px 0;
}
