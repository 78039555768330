.full-height {
    height: 100vh; 
    overflow: hidden; 
  }
  
  .carousel-item img {
    height: 100%; 
    object-fit: cover; 
  }

  .sliderContainer {
    position: relative;
    width: 100%;
    overflow: hidden; 
  }
  .slider {
    display: flex; 
    transition: transform 0.5s ease-in-out;
  }
  
  .slide {
    min-width: 100%;
    height: 100vh; 
    background-size: cover;
    background-position: center; 
  }
  
  .slide img {
      width: 100%; 
      height: auto;
  }
  
  .sliderButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  .sliderButtonPrev {
    left: 10px;
  }
  
  .sliderButtonNext {
    right: 10px; 
  }
  
  .sliderButton:hover {
    background-color: rgba(0, 0, 0, 0.8); 
  }
  
  .translate-0 {
    transform: translateX(0%);
  }
  
  .translate-1 {
    transform: translateX(-100%);
  }
  
  .translate-2 {
    transform: translateX(-200%);
  }