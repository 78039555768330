.cookie-consent-modal {
  position: fixed;
  padding: 1rem;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #f8f9fa;
  color: #333;
  font-family: 'Segoe UI', 'Roboto', sans-serif;
  z-index: 10000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.3); 
  font-size: 0.7rem;
  line-height: 1.6;
  border-top: 3px solid #2980b9;
}

.cookie-consent-modal .cookie-content {
  flex: 1;
  text-align: left;
  margin-right: 2rem;
}

.cookie-consent-modal .cookie-actions {
  display: flex;
  gap: 1rem;
}

.cookie-consent-modal .cookie-btn {
  padding: 0.7rem 1.5rem;
  border: 2px solid transparent; 
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  font-size: 0.85rem; 
  letter-spacing: 0.02rem;
}

.cookie-consent-modal .cookie-btn.accept {
  background-color: #c12331;
  color: white;
}

.cookie-consent-modal .cookie-btn:hover {
  border-color: #ecf0f1;
  background-color: #c12331;
}

@media (max-width: 600px) {
  .cookie-consent-modal {
    flex-direction: column;
    padding: 1rem;
  }

  .cookie-consent-modal .cookie-content ,
  .cookie-consent-modal .cookie-actions {
    flex-direction: row;
    margin-right: 0;
  }

  .cookie-consent-modal .cookie-btn {
    flex-grow: 1;
    margin-top: 1rem;
  }
}
