.info-page-link {
  padding: 8px 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  border-bottom: 1px solid #cacaca;
  margin-right: 30px;
  gap: 6px;
}

.info-page-link:hover {
  font-weight: 500;
  color: #c12331;
  border-bottom: 1px solid #c12331;
}

.active {
  color: #c12331;
}
